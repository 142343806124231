import React, {useState, useEffect} from 'react'
import api from '../util/Api'
import {Grid,Stack,CircularProgress, Typography, Paper, Box, Divider, Button, Rating } from '@mui/material'
import ResponsiveAppBar from './ResponsiveAppBar';
import Footer from './Footer';
import AddTestimonial from '../dialogs/AddTestimonial';
import styled from '@emotion/styled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#a6b396',
    },
    '& .MuiRating-iconHover': {
      color: '#a6b396',
    },
});


export default function TestimonialList({limit, sx}) {
    
    const [refresh, setRefresh] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading ] = useState(true);
    const [toggleAdd, setToggleAdd] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);  
        setLoading(true);
        setTestimonials([]);
        let controller = new AbortController();
        const config = { 
            'accept': 'application/json',
            'signal': controller.signal,
        };

        const url = limit ? `/testimonials/limit/${limit}` : '/testimonials';
        
        const fetchData = async() => {  
            var response = await api.get(url, {}, config);
            if(response.status === 200){
                console.log(response.data);
                setTestimonials(response.data); 
                setLoading(false); 
            }     
        }

        fetchData().catch(console.error);
        return () => controller.abort();
    }, [refresh]);
    return ( 
        <Grid container justifyContent="center" alignItems="stretch" sx={{...sx}}>
            {testimonials.length > 0
                ? (testimonials.map((t, index) => {
                    return(
                                    
                        <Grid flex item xs={12} sm={6} md={4} sx={{padding:{xs:'0', sm:'20px'}}}> 
                            <Stack sx={{ border:{xs:'none', sm:'1px solid #f5f5f5'}, borderBottom:{xs:'1px solid #f5f5f5'},  borderRadius:'5px', padding:'20px',  height:'calc(100% - 40px)'}}>
                                    <StyledRating
                                        defaultValue={t.rating}
                                        precision={1}
                                        readOnly = {true}
                                        icon={<FavoriteIcon fontSize="inherit" />}
                                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                    />

                                    <Typography flexGrow={1} sx={{fontSize:'1em', fontStyle:'italic'}}>
                                        "{t.notes}"
                                    </Typography>
                                    <Typography sx={{fontSize:'1em', fontWeight:'bold', textAlign:'right'}}>
                                        - {t.name}
                                    </Typography>                                     
                            </Stack>                             
                        </Grid>
                    )
                }))
                            
                :  
                    <Grid item xs={12}>
                        <Typography sx={{fontSize:'1em', fontStyle:'italic', textAlign:'center', padding:'20px 0'}}>
                            There are no testimonials yet. Be the first to leave a review. We would greatly appreciate it!
                        </Typography> 
                    </Grid>
                }
        </Grid>
    )
}