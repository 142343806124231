import React, {useEffect, useState, useRef} from 'react'
import { Grid, Stack, Typography, Dialog, DialogActions, DialogContent, Backdrop, DialogTitle, TextField, Alert, Button, Rating, CircularProgress, Box} from '@mui/material';
import styled from '@emotion/styled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import api from '../util/Api';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const StyledTextField = styled(TextField)({

    '& .MuiOutlinedInput-root': {
        
        backgroundColor:"#FFFFFF",
        '& fieldset': {
            border:"1px solid #e8e8e8",
        },
    },

    '& .MuiOutlinedInput-root:hover': {
        '& fieldset': {
            border:"1px solid #e8e8e8",
        },
    },
    '& .MuiOutlinedInput-input': {
        border:0  
    },
});

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#a6b396',
    },
    '& .MuiRating-iconHover': {
      color: '#a6b396',
    },
});

export default function AddTestimonial({open, close, setRefresh}) {

    const captchaRef = useRef(null)
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        rating: 0,
        notes: ""
    });
    const [alert, setAlert] = useState({show:false,message:null});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const handleSubmit = () => {
        
        if(loading) return;
        if(!validateForm()) return;
        setLoading(true);
        
        const config = { 'content-type': 'application/json'};
        
        const postData = async() => {  
            const captchaValue = captchaRef.current.getValue();                       
            var response = await api.post('/testimonials', {...formData, captchaValue}, config);
            if(response.status === 200){
                console.log(response);
                setLoading(false);
                setSuccess(true);
                setRefresh((prevState) => {return !prevState}); 
            }else{
                setAlert({show:true, message:"An issue occured while creating your testimonial. Please try again later."});
                console.log(response);
            }     
        }
        
        postData().catch((err) => {
            setAlert({show:true,message:"An unexpected error occured. Please try again later."});
            setLoading(false);
        });  
    }

    const handleChange = (e) => {
        console.log("here")
        const newValues = {...formData, [e.target.name]: e.target.value};
        setFormData(newValues);
    }

    const updateRating = (newValue) => {
        const newValues = {...formData, rating: newValue};
        setFormData(newValues);
    }

    const validateForm = () => {
        setAlert({show:false,message:null});

        let e = [];              
        var emailRe = /\S+@\S+\.\S+/;
        if(!formData.name || formData.name === null) e.push("name");
        if(!formData.email || formData.email === null || !emailRe.test(formData.email)) e.push("email");
        if(!formData.rating || formData.name === 0) e.push("rating");
        if(!formData.notes || formData.notes === null) e.push("notes");
        
        //ReCaptcha
        const captchaValue = captchaRef.current.getValue();
        if(!captchaValue || captchaValue === null){e.push("recaptcha");}                 
        
        setErrors(e); 

        if(e.length > 0){
            setAlert({show:true,message:"Highlighted fields are required."});
            console.log("return false");
            return false;
            
        } 

          
        return true;            
    }

    const handleClose = () => {
        setErrors([]);
        setAlert({show:false,message:null})
        setSuccess(false);
        setFormData({
            name: "",
            email: "",
            rating: 0,
            notes: ""
        })
        
        close();
    }

    return (
      <Dialog open={open} onClose={close}>
        <Backdrop open = {loading} sx={{ color: '#fff', zIndex:'1'}}> 
            <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5"}}/>
        </Backdrop>
        <DialogTitle sx={{fontSize:'1.5em'}}>
            {
                success 
                    ? "Success"
                    : "Add Your Testimonial"
            }
            </DialogTitle>

        <DialogContent sx={{position:'relative'}}>
  
            <Grid container justifyContent="center" alignItems="center" >  
                    { success       
                        ?  <Grid item xs={12} >
                                <Typography sx={{fontSize:'1.15em'}}>Thank you! Your feedback is much appreciated!</Typography>
                            </Grid>
                        
                        :   <>
                            <Grid item xs={12} >
                                {alert.show &&
                                    <Alert severity="error">{alert.message}</Alert>
                                }
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop:'20px'}}>
                                <StyledTextField
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.name}
                                    onChange = {(e) => handleChange(e)}
                                    error ={errors.includes("name") }
                                />
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop:'30px'}}>
                                <StyledTextField
                                    name="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="outlined"    
                                    value={formData.email} 
                                    onChange = {(e) => handleChange(e)}  
                                    error ={errors.includes("email") }
                                />
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop:'30px'}}>
                                <Stack direction="horizontal" sx={{border: errors.includes("rating") ? '1px solid #d50000' : '', padding:'10px', borderRadius:'5px'}}>
                                    <Typography sx={{paddingRight:'10px'}}>Rating (1-5):</Typography>
                                    <StyledRating
                                        defaultValue={formData.rating}
                                        precision={1}
                                        readOnly = {false}
                                        icon={<FavoriteIcon fontSize="inherit" />}
                                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                        onChange= {(e, newValue) => updateRating(newValue)}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{paddingTop:'30px'}}>
                                <TextField 
                                    multiline 
                                    minRows={3}
                                    maxRows={3}
                                    name="notes"
                                    placeholder='Add your testimonial here...'
                                    value={formData.notes}
                                    onChange = {(e) => handleChange(e)}
                                    error ={errors.includes("notes")}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 255
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} justifySelf="center" sx={{paddingTop:'30px'}}>
                                <Stack direction="horizontal" 
                                        sx={{borderRadius:'5px',
                                        transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                      <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                                        ref={captchaRef}
                                        style={{border: errors.includes("recaptcha") ? '1px solid #d50000' : '',}}/>
                                </Stack>
                            </Grid>
                        </>
                }
            </Grid>
    
            </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {!success && <Button onClick={handleSubmit} >Submit</Button>}
        </DialogActions>

      </Dialog>
    );
}