import React, {useState} from 'react'
import { TextField, Grid, FormLabel, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';

export default function Contact() {
  
  return (
    <>
      <ResponsiveAppBar/>
    
      <div className="contentWrapper">  
        <div style={{width:"calc(60% - 80px)", marginLeft:"20%", borderRadius:"10px", padding:"40px"}}>
          <Grid container rowGap={5}>
          <Grid item xs={12}>
              <p style={{fontWeight:"500", color:"#a6b396", fontSize:"1.5em", verticalAlign:"middle"}}>Send us a message <EmailIcon/></p>
            </Grid>
            <Grid item xs={12}>
              <TextField placeholder="Name" sx={{width:"100%"}}/>
            </Grid>
            <Grid item xs={12}>
              <TextField placeholder="Email" sx={{width:"100%"}}/>
            </Grid>
            <Grid item xs={12}>
              <TextField placeholder="What's on your mind?" rows={10} sx={{width:"100%"}} multiline/>
            </Grid>
            <Grid item xs={9}/>
            <Grid item xs={3} alignContent="center">
              <Button>Send</Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </>
  );
}

