import axios from 'axios'
//import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: 'https://thesagedoor.com/api/',
    //withCredentials: true
});

// api.interceptors.response.use((response) => {
//     return response;
// },(error) => {
//     //Changed from 401 to 400 to prevent browser sign in popup
//     if(error.response.status === 400){
//         //Logout
//         Cookies.remove('workstation_user_logged_in', {expires: 86400, sameSite: 'lax'});
//         localStorage.clear();
//         window.location.href='http://192.168.1.12/Workstation-New/workstation/build/login';
//         return Promise.reject();
//     }
//     return Promise.reject(error);
// });

export default api;