import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Grid, Stack, Box, Typography, Button } from '@mui/material';

export default function Banner({image, title, text, url, btn, imgWidth, contentBGColor, color, align }) {

    const ref = useRef(null);
    const [imgHeight, setImgHeight] = useState('0px');
    const contentWidth = 12 - imgWidth;
    const textColor = color ? color : '#ffffff';
    //const newText = text && text.length > 0 ? text.split('\n').map(str => <p>{str}</p>) : "";
    let btnColor = "#383838";
    let btnTextColor = "#ffffff";
    btnColor += " !important";
    btnTextColor += " !important";
    const btnAlign = align === "left" ? 'right' : 'center';

    const handleResize = () => {
        let h = title ? ref.current.clientHeight : 450;    
        setImgHeight(h + 'px');
        console.log(h)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        handleResize();
    }, [])

    const handleURL = (url) => {
        window.location.href = url;
    }

    return (
        <Grid container className='banner' sx={{padding:{xs:'0 20px 0 20px', md:'0'}}}>
            {image &&
            <Grid item xs={12} sm={imgWidth}
                sx={{height:{xs:'220px', sm: '325px', lg:imgHeight}, 
                    width:'100%',
                    backgroundSize:'cover',
                    backgroundPosition:'10% 75%',
                    backgroundImage: `url(/images/${image})`,  
                    cursor: 'pointer',
                    
                }}
                onClick={() => handleURL(url)}
            />
            }
            
            <Grid ref={ref} item xs={12} sm={contentWidth}
                sx={{background: contentBGColor, 
                    color:'#fff',
                    height:{xs:'50%', sm:'100%'}, 
                    width:{xs:'100%', sm:'34%'},
                    justifyContent:'center',
                    alignItems:'flex-start',
                    display:'flex',
                    position:'relative'}}>
                        
              {title && text 
              &&  
                <Grid container sx={{height:'auto', padding:{xs: '35px 30px 35px 30px', md:'50px 40px 50px 40px'}, textAlign:align, alignItems:'center'}}>
      
                    {title &&

                        <Grid xs={12} item  >
                            <Typography sx={{fontSize:'2em', fontWeight:'bold', color:textColor, fontFamily:'Gelasio !important'}}>{title}</Typography>
                            <Typography sx={{display:{xs:'flex', sm:'none'},fontSize:'0.95em', fontFamily:'Poppins !important', alignItems:'center', color:textColor, textAlign:align}}>{text.substring(0, 150)}...</Typography>
                            <Typography sx={{display:{xs:'none', sm:'flex'},fontSize:'0.95em', fontFamily:'Poppins !important', alignItems:'center', color:textColor, textAlign:align}}>{text}</Typography>
                            <Button className="bannerBtn" href={url}  sx={{background:btnColor, color:btnTextColor, float:'right', marginTop:{xs:'10px', md:'20px'}}}>{btn}</Button>
                        </Grid>
                    } 
                </Grid>
            }
            </Grid>
            
        </Grid>
    );
}
