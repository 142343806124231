import React, {useState, useEffect, useParams} from 'react'
import { Link } from 'react-router-dom';
import { Container, Stack, Grid, Button, Typography, CircularProgress, Box } from '@mui/material';
import { CartItem } from '../components/CartItem';
import { useCart } from '../contexts/CartContext';
import api from '../util/Api';
import { useLocation } from 'react-router-dom';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';

export default function Success() {
  const { items, clearCart } = useCart();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [invoice, setInvoice] = useState({});

  useEffect(() => {

    const config = { 'content-type': 'application/json',};
    const queryParams = new URLSearchParams(location.search);
    const sessionID = queryParams.get('session_id');

    if(sessionID){
      const fetchData = async() => {  
          var response = await api.post('/order/success', {sessionID:sessionID, items:items}, config);          
          if(response.status === 200){          
            const session = response.data.session;
            const lineItems = response.data.lineItems;
            setInvoice({
              "name" : session.customer_details.name,
              "email": session.customer_details.email,
              "address_1": session.shipping_details.address.line1,
              "address_2": session.shipping_details.address.line2,
              "city": session.shipping_details.address.city,
              "state": session.shipping_details.address.state,
              "postalCode": session.shipping_details.address.postal_code,
              "country": session.shipping_details.address.country,
              "subtotal": session.amount_subtotal / 100,
              "tax": session.total_details.amount_tax / 100,
              "shipping": session.total_details.amount_shipping / 100,
              "discount": session.total_details.amount_discount / 100,
              "total" : session.amount_total / 100,
              "paymentStatus": session.payment_status,
              "status": session.status,
              "lineItems": lineItems.data
            });
 
            clearCart();  
            setLoading(false); 
          }     
      }

      fetchData().catch((e) => {setLoading(false); });

          
      
    }else{
      //If no session id return to cart
      window.location="/cart";
    }
  }, []);



  return (<>
      {loading 
      ? <Grid container xs={12} justifyContent="center" alignItems="center" sx={{height:'100vh', width:'100vw'}}>
          <Grid item >
            <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5", justifySelf:'center'}}/>
          </Grid>
        </Grid>
      : <>
        <ResponsiveAppBar />     
        <Stack className="contentWrapperPadded">  


            <Stack sx={{padding:'20px 0 20px 0', textAlign:'center'}}>
                
                
                {Object.keys(invoice).length > 0 
                  ?<>
                    <Typography sx={{fontWeight:'bold', fontSize:'1.5em'}}>Thank you for your order, {invoice.name}!</Typography>
                      <Typography sx={{fontSize:'1em'}}>
                          Your order should ship within 3-5 business days. If you have any questions, feel free to reach out via email at&nbsp; 
                          <Link style={{textDecoration:'', color:'#000'}} onClick={(e) => {e.preventDefault(); window.location.href='mailto:info@thesagedoor.com'; }}>
                              info@thesagedoor.com
                          </Link>
                      </Typography>
                    </>
                  :<>
                  <Typography sx={{fontWeight:'bold', fontSize:'1.5em'}}>Uhoh! There seems to have been an issue.</Typography>
                    <Typography sx={{fontSize:'1em'}}>
                        We are sorry, but an issue has occured while placing your order. Please contact us at your earliest convenience via email at&nbsp; 
                        <Link style={{textDecoration:'', color:'#000'}} onClick={(e) => {e.preventDefault(); window.location.href='mailto:info@thesagedoor.com'; }}>
                            info@thesagedoor.com
                        </Link>
                    </Typography>
                  </>
                }
            </Stack>

              <Stack sx={{flexGrow:1, padding:'30px 0', margin:'0 auto'}}>
              
                    {
                        // invoice.length > 0
                        // ? (purchased.map((item) => {
                        //     return(
                        //         <CartItem key={item.id} {...item} type={1}/>
                        //     )
                        // }))
                        // :<Stack sx={{textAlign:'center'}}>Something went wrong. Please contact us at info@thesagedoor.com</Stack>

                        //Show line items
                        Object.keys(invoice).length > 0
                        ?<Grid container justifyContent="center" alignItems="flex-start" columnSpacing={10} sx={{borderTop:'1px solid #f0f0f0'}}>
                            {/* Shipping Information */}
                            <Grid item xs={12} md={5} >
                              <Stack direction = "horizontal" sx={{justifyContent:'center', alignItems:'center', padding:'20px', marginTop:'20px'}} >
                                    <Grid container sx={{justifyContent:'center', alignItems:'center', textAlign:'left'}}>
                                      <Grid item xs={12}><Typography sx={{fontWeight:"bold", fontSize:'1.5em'}}>Order Summary</Typography></Grid>
                                      <Grid item xs={12}><Typography> An email receipt including the details about your order has been sent to {invoice.email}.</Typography></Grid>
                                      <Grid item xs={12}><Typography sx={{fontWeight:"bold", marginTop:'25px', textDecoration:'underline'}}>This order will be shipped to:</Typography></Grid>
                                      <Grid item xs={12}>{invoice.name}</Grid>
                                      <Grid item xs={12}>{invoice.address_1}</Grid>
                                        {invoice.address_2 &&
                                          <Grid item xs={12}>{invoice.address_2}</Grid>  
                                        }
                                      <Grid item xs={12}>{invoice.city}, {invoice.state} {invoice.postalCode}</Grid>
                                  </Grid>
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={7} sx={{borderLeft:'1px solid #f0f0f0'}}>
                              <Stack direction = "horizontal" sx={{padding:'20px', marginTop:'20px'}} >
                                <Grid container xs={12}>
                                  <Grid item xs={6} sx={{borderBottom:'1px solid #f0f0f0', padding:'10px 0', fontWeight:'bold'}}>Item</Grid>
                                  <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'10px 0', fontWeight:'bold'}}>Price</Grid>
                                  <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'10px 0', fontWeight:'bold'}}>Qty</Grid>
                                  <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'10px 0', fontWeight:'bold', textAlign:'right'}}>Subtotal</Grid>
                                    {invoice.lineItems.length > 0
                                      && invoice.lineItems.map((item) => {
                                        return(
                                          <>
                                            <Grid item xs={6} sx={{borderBottom:'1px solid #f0f0f0', padding:'20px 0'}}>
                                              {item.description}
                                            </Grid>
                                            <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'20px 0'}}>
                                              ${(item.price.unit_amount / 100).toFixed(2)}
                                            </Grid>
                                            <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'20px 0'}}>
                                              {item.quantity}
                                            </Grid>
                                            <Grid item xs={2} sx={{borderBottom:'1px solid #f0f0f0', padding:'20px 0',textAlign:'right'}}>
                                              ${(item.amount_subtotal / 100).toFixed(2)}
                                            </Grid>  
                                          </>
                                        )
                                      })}
                                  
                                  {/* Price Information */}
                                  
                                  <Grid item xs={6} md={10} sx={{textAlign:{xs:'left', md:'right'}, paddingTop:'10px'}}>Subtotal:</Grid>
                                  <Grid item xs={6} md={2} sx={{textAlign:'right', paddingTop:'10px'}}>${invoice.subtotal.toFixed(2)}</Grid>
                                  <Grid item xs={6} md={10} sx={{textAlign:{xs:'left', md:'right'}, paddingTop:'10px'}}>Tax:</Grid>
                                  <Grid item xs={6} md={2} sx={{textAlign:'right',paddingTop:'10px'}}>${invoice.tax.toFixed(2)}</Grid>
                                  <Grid item xs={6} md={10} sx={{textAlign:{xs:'left', md:'right'},paddingTop:'10px'}}>Flat Shipping Rate:</Grid>
                                  <Grid item xs={6} md={2} sx={{textAlign:'right', paddingBottom:'10px',paddingTop:'10px'}}>$7.99</Grid>
                                  <Grid item xs={6} md={10} sx={{textAlign:{xs:'left', md:'right'}, borderTop:'1px solid #f0f0f0', paddingTop:'10px'}}>Total:</Grid>
                                  <Grid item xs={6} md={2} sx={{textAlign:'right',fontWeight:'bold',borderTop:'1px solid #f0f0f0', paddingTop:'10px'}}>${invoice.total.toFixed(2)}</Grid>
              
                                </Grid>
                              </Stack>
                            </Grid>
                        </Grid>                                
                        :<Stack></Stack>
                      }                       
              </Stack>
          </Stack>     
          <Footer />
        </>
    }
    </>
  );
}

