import { useCart } from "../contexts/CartContext";
import { Stack, Button, Grid, Select, MenuItem, Typography } from "@mui/material";

export function CartItem({id, name, images, quantity, price, sales_price, instock, qtyUpdatedFlag = false})  {
    const { removeItem, updateQty } = useCart();
    const selectArr = [];

    const quantities = () => {
        if(instock > 0){
            for(let i = 1; i <= instock; i++){
                selectArr.push(<MenuItem id={i} value={i}>{i}</MenuItem>);
            }      
        }   
        return selectArr;
    }
                              
    return(
        <Stack direction = "horizontal" gap={2} alignItems="center" sx={{padding:'30px 30px', borderBottom:'1px solid #f0f0f0'}}>
            <Grid container columnSpacing={4} sx={{display:'flex', justifyContent:{xs:"center", lg:'flex-start'}, alignItems:"center"}}>
                {images[0].original && 
                    <Grid item xs={12} md={5} lg={4} xl={3} sx={{justifyContent:{xs:"center", lg:'flex-start'}}}>               
                        <img src={images[0].original} style={{ maxWidth:'250px',  objectFit:{xs:'fit', md:'cover'}}}/>                
                    </Grid>
                }
                
                <Grid item xs={12} md={7} lg={8} xl={9}> 
                    <Grid container>
                        <Grid item xs={12} sx={{fontWeight:'500', fontSize:'1.1em', marginTop:'5px'}}>
                            <Typography sx={{fontSize:'1.1em', fontWeight:'bold', fontFamily:'Gelasio !important', textAlign:{xs:'center', md:'left'}}}>{name}</Typography>
                        </Grid>

                        <Grid container rowSpacing={0.5} >
                            <Grid item xs={12} sx ={{textAlign:'left', fontWeight:'500', fontSize:'1.25em', textAlign:{xs:'center', md:'left'}}}>         
                                ${((sales_price ? sales_price : price) * quantity).toFixed(2)}&nbsp;
                                {quantity > 1 && <span style={{fontSize:'.8em', color:'#c0c0c0'}}>(${sales_price ? sales_price : price} each)</span>}     
                            </Grid>
                            {/*instock > 0 && instock < quantity &&*/
                            qtyUpdatedFlag &&
                                <Grid item xs={12} sx ={{textAlign:'left', fontWeight:'500', textAlign:{xs:'center', md:'left'}, marginTop:'10px', color:'#FF0000'}}> 
                                    <Typography sx={{fontSize:'0.9em', color:'#F51414', textAlign:{xs:'center', md:'left'}}}>We're sorry! We had to lower the quantity. The requested quantity is no longer available. </Typography>
                                </Grid>
                            }
                            <Grid item xs={12} sx={{marginTop:'10px', textAlign:{xs:'center', md:'left'}}}>
                                {instock > 0
                                    ?(<Select id={id+'-qty'} value={quantity} onChange={(e) => updateQty(id, e.target.value)} sx={{width:{xs:'100%', md:'50%'}, textAlign:{xs:'center', md:'left'}, maxWidth:{md:'200px'}}}>
                                        {quantities()}
                                    </Select>)
                                    :<Typography sx={{fontSize:'0.9em', color:'#F51414', textAlign:{xs:'center', md:'left'}}}>We're sorry! This item is no longer available. Please remove it from your cart to proceed.</Typography>
                                }
                            </Grid>
                            
    
                            <Grid item xs={12} sx={{textAlign:'left', fontSize:'1em', color:'#484848',textAlign:{xs:'center', md:'left'}}}>
                                <Button className='sage' onClick={() => removeItem(id)}>
                                    Remove
                                </Button>
                            </Grid>

                        </Grid>     
                    </Grid>              
                </Grid>                         
            </Grid>
        </Stack>
    )
    
}

