import React, {useState, useEffect} from 'react'
import api from '../util/Api'
import { Grid,Box,CircularProgress } from '@mui/material'
import ProductCard from '../components/ProductCard'
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';
import CategoryNav from '../components/CategoryNav';


export default function OnSale() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading ] = useState(true);
    
  
    useEffect(() => {
        window.scrollTo(0,0);  
        setLoading(true);
        setProducts([]);
        let controller = new AbortController();
        const config = { 
            'accept': 'application/json',
            'signal': controller.signal,
        };

        const fetchData = async() => {  
            var response = await api.get('/on-sale/', {}, config);
            if(response.status === 200){
                setProducts(response.data); 
                setLoading(false); 
            }     
        }

        fetchData().catch(console.error);
        return () => controller.abort();
    }, []);


  return ( 
    <>
        <ResponsiveAppBar />
    
        <div className="contentWrapper">
            {loading 
                ?<Grid container xs={12} justifyContent="center" alignItems="center" sx={{height:{xs:'250px', md:'350px', lg:'500px'}}}>
                    <Grid item >
                    <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5", justifySelf:'center'}}/>
                    </Grid>
                </Grid>
                  
                :<>
                    {/* <Box display="flex" sx={{height:{xs:'250px', md:'350px', lg:'500px'}, 
                        width:'100%', 
                        backgroundSize:'cover', 
                        backgroundPosition:'0% 60%',  
                        backgroundImage: 'url(images/headers/christmasHeader.jpg)',
                        position:'relative'}}/> */}

                    <Grid container sx={{marginTop:{xs:'0', md:'50px'}}} columnSpacing={10}>
                        
                        <Grid item md={3} sx={{display:{xs:'none', md:'block'}, textAlign:'left', position:'relative'}}>
                            <CategoryNav/>
                        </Grid>

                        {/************************************Products***********************************/}
                        <Grid item xs={12} md={9}>
                           
                            <Grid container columnSpacing={5} rowGap={5} sx={{padding:{xs:'0', md:'50px 0 50px 0'}}} >
                                {products.length > 0
                                    ? (products.filter( x => x.active === 1).map((p, index) => {
                                        return(
                                            <Grid item id={index}  xs={12} sm={6} xl={4}>
                                                <ProductCard id = {p.id} {...p}  />
                                            </Grid>
                                        )
                                    }))
                                    : <Grid item xs={12}  display="flex" style={{textAlign:"center", width:"100%", marginTop:"20px", justifyContent:'center'}}>No products&nbsp;<i>currently</i>&nbsp;on sale.</Grid> 
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
        <Footer />
    </>
  );
}