import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Stack } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Footer() {

  return (
    <>

    <div className='footerWrapper'>
      <div className='footer'>
        <Grid container sx={{display:'flex', alignItems:'center'}}>
          <Grid item xs={12} md={4} sx={{padding:{xs:'30px', md:'50px 50px 50px 0px'}, justifyContent:{xs:'center', md:'flex-start'}, alignItems:'center', textAlign:{xs:'center', md:'left'}}}>
            <Typography sx={{fontSize:'1em', textAlign:'justify'}}>"By wisdom a house is built, and through understanding it is established; By knowledge the rooms are filled with rare and beautiful treasures."</Typography>
            <Typography sx={{color:"#C0C0C0", fontStyle:'italic', textAlign:'right', fontSize:'1em'}}>Proverbs 24:3-4</Typography>
            {/* <Box sx={{maxWidth:'500px', padding:'10px 15px',}}>
                    <Typography sx={{fontWeight:'bold',textDecoration:'underline', fontSize:'1em'}}>Return Policy</Typography>
                    <Typography sx={{fontSize:'1em'}}>All online sales are final. Please report any damaged items immediately. Thank you!</Typography>
              </Box> */}
          </Grid>
          
          
          <Grid item xs={12} md={4} 
            sx={{borderLeft:{xs: 0, md:'1px solid #f0f0f0'}, 
            borderRight:{xs:0, md:'1px solid #f0f0f0'}, 
            borderTop:{xs:'1px solid #f0f0f0', md:0}, 
            borderBottom:{xs:'1px solid #f0f0f0', md:0}, 
            padding:'40px 30px', 
            textAlign:'center',
            alignItems:'center',
            justifyContent:'center'
            }}>  
           
            <Typography sx={{fontSize:"1em"}}>&copy; {new Date().getFullYear()} The Sage Door, LLC </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{padding:'20px'}}>
            <Grid container sx={{justifyContent:{xs:'center', md:'flex-end'}, alignItems:'center'}}>
             
           
              
              <Grid item xs={12} sx={{justifyContent:{xs:'center', md:'flex-end'},  alignItems:'center', marginTop:'10px', textAlign:{xs:'center', md:'right'}}}>
                
              
                  <Grid container sx={{justifyContent:{xs:'center', md:'flex-end'}, alignItems:'center'}}>
                    <Grid item>
                      <Typography sx={{fontSize:"1em", justifyContent:'center',  alignItems:'center'}}>Follow us on:  </Typography>
                    </Grid>
                    <Grid item>
                      <Link to = "https://www.facebook.com/profile.php?id=61552396435838" target="_blank" style={{textDecoration:'none', color:"#000"}}>
                        <FacebookIcon sx={{fontSize:'2em', marginLeft:'5px'}}/>
                      </Link>
                    </Grid>
                  <Grid item>
                    <Link to = "https://www.instagram.com/the_sage_door/" target="_blank" style={{textDecoration:'none', color:'#000000'}}>
                      <InstagramIcon sx={{fontSize:'2em', marginLeft:'3px'}}/>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} item sx={{textAlign:{xs:'center', md:'right'}, marginTop:{xs:'10px', md:'0'}}}>               
                <Typography sx={{fontSize:"1em"}}><Link style={{textDecoration:'underline', color:'#000'}} onClick={(e) => {e.preventDefault(); window.location.href='mailto:info@thesagedoor.com'; }}>Need assistance? Click here to email us!</Link>  </Typography>
              </Grid>
            </Grid>

           
          </Grid>
         
        </Grid>
      </div>
      <Stack sx={{justifyContent:'center', alignItems:'center', textAlign:'left', background:'rgb(233,229,213,.35)',  borderTop:'1px solid #FFFFFF3', padding:'25px', }}>       
        <Typography sx={{fontWeight:'bold', fontSize:'.95em', textDecoration:'underline'}}>Our Return Policy</Typography>
        <Typography sx={{fontSize:'.85em'}}>All online sales are final. Please report any damaged items immediately.</Typography>
      </Stack>
    </div>
    </>
  );
}
