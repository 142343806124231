import React, {useState} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { ThemeProvider, createTheme } from "@mui/material/styles";

//Pages
import Home from './pages/Home';
import Products from './pages/Products'
import About from './pages/About';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import Product from './pages/Product';
import Success from './pages/Success';
import OnSale from './pages/OnSale';
import Testimonials from './pages/Testimonials';

//Material UI Fonts
import '@fontsource/roboto/300.css'; 
import '@fontsource/roboto/400.css';  
import '@fontsource/roboto/500.css';  
import '@fontsource/roboto/700.css';  
import { CartProvider } from './contexts/CartContext';

const theme = createTheme({
  palette: {
    primary:{
      main: "#a6b396"
    },
    secondary:{ 
      main: "#e9e5d5"
    }
  }
})

export default function App() {
  
  return (
    <ThemeProvider theme={theme}>   
      <div className='wrapper' >     
        <Router basename={'/'}>  
           
            <CartProvider>
                <Routes>
                  <Route exact path="/" element={<Home/>}></Route> 
                  <Route path="/bath" element={<Products category="bath" title="Bath" image='bathHeader.jpg' bgPosition='center center'/>}></Route> 
                  <Route path="/books" element={<Products category="books" title="Books + Stationery" image='booksHeader.jpg' bgPosition='center center'/>}></Route> 
                  <Route path="/cleaning" element={<Products category = "cleaning" title="Cleaning" image='cleaningHeader.jpg' bgPosition='center center'/>}></Route> 
                  <Route path="/food" element={<Products category="food" title="Food + Drink" image='foodHeader.jpg' bgPosition='center'/>}></Route>  
                  <Route path="/fragrances" element={<Products category="fragrances" title="Fragrances" image='candlesHeader.jpg' bgPosition='center'/>}></Route>   
                  <Route path="/decor" element={<Products category = "decor" title="Decor" image='decorHeader.jpg' bgPosition='center center'/>}></Route>    
                  <Route path="/gifts" element={<Products category = "gifts" title="Gifts" image='giftsHeader.jpg' bgPosition='center center'/>}></Route>       
                  <Route path="/kitchen" element={<Products category = "kitchen" title="Kitchen" image='kitchenHeader.jpg' bgPosition='center center'/>}></Route>    
                 
                  <Route path="/on-sale" element={<OnSale />}></Route>  
                  <Route path="/about" element={<About />}></Route>        
                  <Route path="/contact" element={<Contact />}></Route>    
                  <Route path="/cart" element={<Cart />}></Route>    
                  <Route path="/success" element={<Success />}></Route> 
                  <Route path="/viewProduct/:id" element={<Product />}></Route>       
                  <Route path="/testimonials/" element={<Testimonials />}></Route>              
                </Routes>             
              </CartProvider>
            
        </Router>
      </div>
      </ThemeProvider>
  );
}

