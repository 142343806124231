import * as React from 'react';
import { Box, Typography } from '@mui/material';


export default function Quote({text, subText, style}) {

  return (
    <Box sx={{width:'100%', height:'auto', 
      ...style,
      alignItems:'center',
      textAlign:'center'}}>
    
      <Typography sx={{fontSize:'1.7em', fontFamily:'Bad Script'}}>
        "{text}"
      </Typography>

      <Typography sx={{fontSize:'1em', color:'#C0C0C0', marginTop:'5px'}}>
        {subText}
      </Typography>
 
    </Box>

   
  );
}
