import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import { useCart } from '../contexts/CartContext';
import api from '../util/Api'
import {Button, Grid, Box, Typography, Stack, Snackbar,CircularProgress } from '@mui/material'
import ImageGallery from "react-image-gallery";
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';


export default function Product() {
    let { id } = useParams();
    const [loading, setLoading ] = useState(true); 
    const [item, setItem] = useState({});
    const { items, addItem } = useCart();
    const [disabled, setDisabled] = useState();
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);   
        setLoading(true);
        setItem({});
        let controller = new AbortController();
        const config = { 
            'accept': 'application/json',
            'signal': controller.signal,
        };

        const fetchData = async() => {  
            var response = await api.get('/products/' + id, {}, config);
            if(response.status === 200){
                console.log(response.data);
                setItem(response.data);     
                isAddDisabled(response.data,false);   
                setLoading(false);
            }     
        }

     fetchData().catch(console.error);
    
        return () => controller.abort();
    }, []);

    function handleAddItem(item){
        addItem(item);
        console.log(item);
        isAddDisabled(item,true); 
        setSnackBarOpen(true); 
    }

    function isAddDisabled(item,add){
        var instock = item.instock;
        if(add) instock--;
        if(items.length > 0){
            items.filter( x => x.id === item.id).map((x) => { instock -= x.quantity });
        }
        setDisabled (instock <= 0);
    }


  return ( 
    <>
    <Snackbar open={snackBarOpen} autoHideDuration={3000} message="Item added to cart" onClose={() => setSnackBarOpen(false)}/>
        <ResponsiveAppBar/>
        <div className="contentWrapper">
            {loading 
                ?<Grid container xs={12} justifyContent="center" alignItems="center" sx={{height:'100vh', width:'100vw'}}>
                    <Grid item >
                    <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5", justifySelf:'center'}}/>
                    </Grid>
                </Grid>
                  
                : <Grid container sx={{marginTop:{xs:'0px', md:'25px'}, display:'flex'}} columnSpacing={6} justifyContent="flex-start" alignItems="center">
                        
                        {/************************************Item***********************************/}
                        {item
                        ? (
                            
                            <>          
                            <Grid item xs={12} md={6} lg={4} > 
                                {item?.images.length > 0 &&
                                    <ImageGallery items={item.images} showNav={false} showPlayButton={false} showFullscreenButton={false} showIndex={false} />
                                }
                            </Grid>
                            
                            <Grid item xs={12} md={6} lg={8} justifyItems="center">
                                <Stack sx={{padding:'20px'}}>
                                    <Stack>
                                        <Typography sx={{fontSize:'1.5em', fontWeight:'bold', fontFamily:'Gelasio !important'}}>{item.name}</Typography>
                                    </Stack>
                                    <Stack sx={{marginTop:'10px', textAlign:'left'}}>
                                        <Typography sx={{fontSize:'1.05em', fontWeight:'bold'}}>
                                            {item.sales_price
                                                ?   <>
                                                        <div style={{color:'#F51414', fontWeight:'bold'}}>SALE</div>
                                                        <span style={{color:'#F51414'}}>${item.sales_price}</span>&nbsp;&nbsp;
                                                        <span style={{textDecoration:'line-through',fontWeight:'normal'}}>${item.price}</span>
                                                    </>
                                                : <>
                                                    ${item.price}
                                                </>                                      
                                            }
                                        </Typography>
                                    </Stack>
                                    <Stack sx={{marginTop:'20px'}}>
                                        <Typography sx={{fontSize:'1em', fontWeight:'normal'}}>{item.description}</Typography>
                                    </Stack>

                                    

                                    <Stack>
                                        <Button sx={{alignSelf:'flex-end', width:'100%'}} className = "sage" onClick={() => handleAddItem(item)} disabled={disabled}>
                                                {!disabled
                                                    ? "ADD TO CART"
                                                    : "OUT OF STOCK"
                                                }
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                            </>                       
                        )

                    : <Grid item xs={12}  display="flex" style={{textAlign:"center", width:"100%", marginTop:"20px", justifyContent:'center'}}>Product not found.</Grid> 
                    }
                </Grid>
            }
        </div>
        <Footer />
    </>
  );
}