import React, {useState, useEffect} from 'react'



export function ImageSlider({slides}) {
  
    const delay = 5000;
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const sliderStyles = {
        height:'100%',
        position: 'relative',    
    }

    const slideStyles ={
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition:`${slides[currentIndex].position}`,
        backgroundImage: `url(${slides[currentIndex].url})`,
        animation: '3s slide-left'
    }

    const leftArrowStyles = {
        position:'absolute',
        top: 'calc(50% - 25px)',
        transition: 'translate(0, -50%)',
        left:'32px',
        fontSize: '50px',
        color: 'rgb(255,255,255,.35)',
        zIndex: 1,
        cursor: 'pointer'
    }

    const rightArrowStyles = {
        position:'absolute',
        top: 'calc(50% - 25px)',
        transition: 'translate(0, -50%)',
        right:'32px',
        fontSize: '50px',
        color: 'rgb(255,255,255,.35)',
        zIndex: 1,
        cursor: 'pointer'
    }

    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setCurrentIndex((prevIndex) =>
              prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [currentIndex]);   


    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }
   
    const goToNext= () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }


    return (
        <div style={sliderStyles}>
            <div style={leftArrowStyles} onClick={goToPrevious}>&#x2039;</div>
            <div style={rightArrowStyles} onClick={goToNext}>&#x203A;</div>
            <div style={slideStyles}></div>
        </div>
    );
}
