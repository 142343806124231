import React, {useState, useEffect} from 'react'
import api from '../util/Api'
import {Grid,Stack,CircularProgress, Typography, Paper, Box, Divider, Button, Rating } from '@mui/material'
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';
import AddTestimonial from '../dialogs/AddTestimonial';
import TestimonialList from '../components/TestimonialList';

export default function Testimonials() {
    
    const [refresh, setRefresh] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading ] = useState(true);
    const [toggleAdd, setToggleAdd] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);  
        setLoading(true);
        setTestimonials([]);
        let controller = new AbortController();
        const config = { 
            'accept': 'application/json',
            'signal': controller.signal,
        };

        const fetchData = async() => {  
            var response = await api.get('/testimonials/', {}, config);
            if(response.status === 200){
                setTestimonials(response.data); 
                setLoading(false); 
            }     
        }

        fetchData().catch(console.error);
        return () => controller.abort();
    }, [refresh]);

    const handleToggleAdd = () => {
        setToggleAdd(!toggleAdd);
    }


  return ( 
    <>
        <ResponsiveAppBar />
    
        <div className="contentWrapper">
            {loading 
                ?<Grid container xs={12} justifyContent="center" alignItems="center" sx={{height:{xs:'250px', md:'350px', lg:'500px'}}}>
                    <Grid item >
                    <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5", justifySelf:'center'}}/>
                    </Grid>
                </Grid>
                  
                :<>
                    <Box sx={{padding:'20px 20px 0 20px'}}>
                        <Typography sx={{fontSize:'1.5em', fontWeight:'600', textAlign:'center', color:'#585858'}}>
                            Your Feedback is Important to Us!
                        </Typography>
                        <Typography sx={{fontSize:'1em', fontWeight:'400', textAlign:'center', color:'#585858'}}>
                            Please leave a review, by clicking the "Add Testimonial" button below.
                        </Typography>
                    </Box>

                    <Box sx={{textAlign:'center'}}>
                        <Button className="sage" onClick={handleToggleAdd}>Add testimonial</Button>
                    </Box>

                    <Divider sx={{width:{xs:'100%', sm:'80%'}, margin:'0 auto', borderColor:'#f5f5f5', marginTop:'20px'}}/>
                    <TestimonialList sx={{marginTop:{xs:'0', sm:'20px'}}}/>
                    
                </>
            }
        </div>
        <Footer />
        <AddTestimonial open={toggleAdd} close={handleToggleAdd} setRefresh={setRefresh}/>
    </>
  );
}