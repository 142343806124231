import React, {useState, useRef, useEffect} from 'react';
import { Grid, Stack, Box, Typography } from '@mui/material';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function About() {
  const ref = useRef(null);
  const [imgHeight, setImgHeight] = useState('0px');
  
  const handleResize = () => {
    let h = ref.current.clientHeight * 1;    
    setImgHeight(h + 'px');
  }

  useEffect(() => {
      window.addEventListener("resize", handleResize, false);
      handleResize();
  }, [])

  return (
    <>
      <ResponsiveAppBar />
    
      <Stack className="contentWrapper">  

      <Box display="flex" sx={{height:{xs:'250px', md:'350px', lg:'600px'}, 
          width:'100%', 
          backgroundSize:'cover', 
          backgroundPosition: '50%, 50%',  
          backgroundImage: `url(images/headers/aboutHeader.jpg)`,
          position:'relative'}}/>
        
      <Stack sx={{borderTop:'1px solid #f2f2f2', margin:{xs:'30px', md:'50px 0'}, borderTop:{xs:0, md:'1px solid #f2f2f2'}}}>
        
        <Grid container justifyContent="space-between" alignItems="center" >

          <Grid ref={ref} item xs={12} md={8} >
            <Typography sx={{fontSize:'2em', fontWeight:'bold', fontFamily:'Gelasio !important',}}>The Sage Door</Typography>
            <Typography sx={{fontSize:'1em', color:'#383838', fontFamily:'Poppins !important', marginTop:'5px'}}>
              The Sage Door started as not only a dream, but a true calling. Creating cozy
              spaces has been a passion of mine ever since I purchased my first home in 2015. 
              I fell in love with how small details could truly elevate a house and turn it into a home.
              You will find that I'm a sucker for neutrals (with a touch of green, of course)
              and all about adding texture and warmth to a space. My goal is to remain timeless, not too trendy, 
              with a mix of different styles. <br /><br />
              A home to me is where you gather with family and feel comfortable with reminders of what's 
              most important to you. It's a place you long to return to even when you're on a paradise vacation. 
              Your home should make you happy, it should make you proud, no matter its size and regardless if you rent or own.<br /><br />
              My purpose and 'calling' for this shop is to spread the word through intentional decor, promote reducing the carbon footprint
              by offering sustainable options, and  
              inspire healthy living through the products we use in our home.<br /><br />
              My hope is that you find joy in the little things this shop has to offer and that you feel encouraged to chase your dreams, strengthen
              your relationship with Jesus, and add pieces to your home that you love. 
              This may be our temporary home, but let's make it a good one.<br /><br />
              Thank you so much for your support <FavoriteIcon sx={{fontSize:'1em', color:'#a6b396', marginBottom:'-2px'}}/> I'm so glad you're here!
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{  
            height:{xs:'450px', md:imgHeight}, 
            width:'100%',
            marginTop:{xs:'20px', md:'100px'},
            backgroundSize:'cover',
            backgroundPosition:{xs:'0% 25%', md: '0% 25%'},  
            backgroundImage: `url(images/aboutMe.jpg)`,
            position:'relative'}}>
          </Grid> 
        </Grid>
      </Stack>
      </Stack>
      <Footer />
    </>
  );
}

